"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getConfig = () => {
    const hostname = window.location.hostname;
    const configs = [
        {
            urlPattern: 'localhost',
            config: {
                auth0CallbackUrl: 'http://localhost:3000',
                auth0ClientId: 'V8zxicpUV710r3YYOmoG7YlRHLmJSahm',
                auth0Audience: 'https://hot-spice-api.azurewebsites.net',
                auth0Domain: 'dev-n6f4l52225yeulli.us.auth0.com',
                //hotspiceApi: 'http://localhost:7071/api'
                hotspiceApi: 'https://hot-spice-api.azurewebsites.net/api'
            }
        },
        {
            urlPattern: 'hot-spice.azurewebsites.net',
            config: {
                auth0CallbackUrl: 'https://hot-spice.ai',
                auth0ClientId: 'V8zxicpUV710r3YYOmoG7YlRHLmJSahm',
                auth0Audience: 'https://hot-spice-api.azurewebsites.net',
                auth0Domain: 'dev-n6f4l52225yeulli.us.auth0.com',
                hotspiceApi: 'https://hot-spice-api.azurewebsites.net/api'
            }
        }
    ];
    // Find the first config where the urlPattern matches the current hostname
    const matchedConfig = configs.find(({ urlPattern }) => hostname.includes(urlPattern));
    // Default config (you might want to throw an error instead)
    const defaultConfig = {
        auth0CallbackUrl: 'https://hot-spice.ai',
        auth0ClientId: 'V8zxicpUV710r3YYOmoG7YlRHLmJSahm',
        auth0Audience: 'https://hot-spice-api.azurewebsites.net',
        auth0Domain: 'dev-n6f4l52225yeulli.us.auth0.com',
        hotspiceApi: 'https://hot-spice-api.azurewebsites.net/api'
    };
    return matchedConfig ? matchedConfig.config : defaultConfig;
};
const config = getConfig();
exports.default = config;
