"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const conversationConsts = [];
/*

  {
    conversationId: '1',
    chatHistory: [],
    botProfileId: 1,
    name: 'Jane',
    unreadCnt: 1,
    lastSenderName: 'Jane',
    info: 'Hello, how are you?',
    profilePicture:
      'https://carsales.pxcrush.net/carsales/car/dealer/5c2c3cba301dbdca1975426763376f52.jpg?pxc_method=gravityfill&pxc_bgtype=self&pxc_size=900%2c600'
  },


*/
exports.default = conversationConsts;
