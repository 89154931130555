"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const Account_1 = require("@shared/types/account/Account");
const useAccount = (props) => {
    const [account, setAccount] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        // TODO - Once we are getting the account level and premium statues. We need to set this here
        const demoAccount = {
            userRef: 'anon|testuser',
            handle: 'TestUser',
            status: Account_1.AccountStatus.Active,
            subscription: Account_1.SubscriptionLevel.Free,
            knownFingerPrints: [''],
            subscriptionDueUtc: '',
            autoRenew: false,
            violationCount: 0,
            lockReason: '',
            utcLockDate: ''
        };
        setAccount(demoAccount);
    }, []);
    return {
        account
    };
};
exports.default = useAccount;
