"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useCompanions = (props) => {
    const [profile, setProfile] = (0, react_1.useState)(null);
    const [localProfiles, setLocalProfiles] = (0, react_1.useState)(null);
    const [localRelationships, setLocalRelationships] = (0, react_1.useState)(null);
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [error, setError] = (0, react_1.useState)(null);
    const getProfile = (companionProfileId) => {
        if (!localProfiles)
            return null;
        const foundProfile = localProfiles.find((x) => x.id === companionProfileId);
        if (!foundProfile)
            return null;
        setActiveProfile(companionProfileId);
        return foundProfile;
    };
    const getProfileRelationship = (companionProfileId) => {
        if (!localProfiles)
            return null;
        const foundRelationship = localRelationships.find((x) => x.companionId === companionProfileId);
        if (!foundRelationship)
            return null;
        return foundRelationship;
    };
    const getAllCompanionsAndRelationships = () => {
        if (localProfiles &&
            localProfiles.length > 0 &&
            localRelationships &&
            localRelationships.length > 0)
            return;
        setIsLoading(true);
        props.useHotSpiceApi.getCompanions().then((res) => {
            const companions = res.data;
            setLocalProfiles(companions);
        });
        props.useHotSpiceApi.getRelationships().then((res) => {
            const relationships = res.data;
            setLocalRelationships(relationships);
            setIsLoading(false);
        });
    };
    const setActiveProfile = (companionProfileId) => {
        setIsLoading(true);
        const foundProfile = localProfiles.find((x) => x.id === companionProfileId);
        setProfile(foundProfile);
        setIsLoading(false);
    };
    return {
        profile,
        isLoading,
        error,
        getProfile,
        setActiveProfile,
        getAllCompanionsAndRelationships,
        localProfiles,
        localRelationships,
        getProfileRelationship
    };
};
exports.default = useCompanions;
