"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useModal = () => {
    const [modalState, setModalState] = (0, react_1.useState)({});
    const registerModal = (modalName) => {
        const newState = {
            ...modalState,
            [modalName]: false
        };
        setModalState(newState);
    };
    const openModal = (modalName) => {
        setModalState({
            ...modalState,
            [modalName]: true
        });
    };
    const closeModal = (modalName) => {
        setModalState({
            ...modalState,
            [modalName]: false
        });
    };
    const isModalOpen = (modalName) => {
        if (modalState[modalName] === undefined)
            return false;
        return modalState[modalName];
    };
    return {
        isModalOpen,
        openModal,
        closeModal,
        registerModal
    };
};
exports.default = useModal;
