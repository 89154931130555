"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionLevel = exports.AccountStatus = void 0;
var AccountStatus;
(function (AccountStatus) {
    AccountStatus["Active"] = "Active";
    AccountStatus["Locked"] = "Locked";
    AccountStatus["DevAccount"] = "DevAccount";
    AccountStatus["FriendAccount"] = "FriendAccount";
})(AccountStatus = exports.AccountStatus || (exports.AccountStatus = {}));
var SubscriptionLevel;
(function (SubscriptionLevel) {
    SubscriptionLevel["Free"] = "Free";
    SubscriptionLevel["Premium"] = "Premium";
})(SubscriptionLevel = exports.SubscriptionLevel || (exports.SubscriptionLevel = {}));
