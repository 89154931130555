"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const SideMenu_1 = require("../components/SideMenu");
const useSidebarNavbar = () => {
    const [selectedKeys, setSelectedKeys] = (0, react_1.useState)([
        SideMenu_1.MenuKeys.EXPLORE
    ]);
    const [isCollapsed, setIsCollapsed] = (0, react_1.useState)(true);
    (0, react_1.useEffect)(() => {
        setIsCollapsed(true);
    }, []);
    const closeSideNavbar = () => {
        setIsCollapsed(true);
    };
    return {
        selectedKeys,
        setSelectedKeys,
        setIsCollapsed,
        closeSideNavbar,
        isCollapsed
    };
};
exports.default = useSidebarNavbar;
