"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useGenerator(props) {
    const [isLoading, setLoading] = (0, react_1.useState)(false);
    const [isGenerating, setGenerating] = (0, react_1.useState)(false);
    const [jobId, setJobId] = (0, react_1.useState)('');
    const [generatedContent, setGeneratedContent] = (0, react_1.useState)([]);
    const [suggestions, setSuggestions] = (0, react_1.useState)(null);
    const generate = (request, onGenerateCallback) => {
        setLoading(true);
        setGeneratedContent([]);
        setGenerating(true);
        setJobId('');
        props.useHotSpiceApi.generateImage(request).then((res) => {
            setLoading(false);
            setGenerating(false);
            onGenerateCallback();
        });
    };
    const getSuggestions = () => {
        setLoading(true);
        props.useHotSpiceApi.getGenerationSuggestions().then((result) => {
            const data = result.data;
            setSuggestions(data);
            setLoading(false);
        });
    };
    return {
        isLoading,
        generate,
        generatedContent,
        isGenerating,
        jobId,
        getSuggestions,
        suggestions
    };
}
exports.default = useGenerator;
