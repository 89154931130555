"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const icons_1 = require("@ant-design/icons");
const antd_1 = require("antd");
const ImageCard = ({ images, title, description, age, onMessageClick }) => {
    const [currentImageIndex, setCurrentImageIndex] = (0, react_1.useState)(0);
    // Handle image cycling on click
    const handleClick = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };
    const { token: { colorPrimary } } = antd_1.theme.useToken();
    // Styles
    const cardStyle = {
        position: 'relative',
        width: '220px',
        height: '400px',
        borderRadius: '15px',
        overflow: 'hidden',
        cursor: 'pointer',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        transition: 'scale 0.3s ease'
    };
    const cardHoverStyle = {
        ...cardStyle,
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
        border: '2px solid #FFFBF0',
        scale: '1.01'
    };
    const imageStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'top'
    };
    const overlayStyle = {
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        padding: '15px',
        height: '120px',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
        textAlign: 'center',
        boxSizing: 'border-box'
    };
    const titleStyle = {
        fontSize: '20px',
        fontWeight: '100',
        margin: '0',
        textAlign: 'left',
        color: colorPrimary
    };
    const descriptionStyle = {
        fontSize: '10px',
        margin: '5px 0 0',
        textAlign: 'left',
        color: 'rgba(255, 255, 255, 0.8)'
    };
    const indicatorsContainerStyle = {
        position: 'absolute',
        bottom: '130px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        gap: '10px'
    };
    const indicatorStyle = (isActive) => ({
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        backgroundColor: isActive
            ? 'rgba(255, 255, 255, 0.9)'
            : 'rgba(255, 255, 255, 0.5)',
        transition: 'opacity 0.3s ease'
    });
    const messageIconStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        width: '34px',
        height: '34px',
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
        transition: 'transform 0.2s ease'
    };
    const messageIconHoverStyle = {
        ...messageIconStyle,
        transform: 'scale(1.3)'
    };
    return ((0, jsx_runtime_1.jsxs)("div", { style: cardStyle, onMouseEnter: (e) => {
            e.currentTarget.style.border = cardHoverStyle.border;
            e.currentTarget.style.scale = cardHoverStyle.scale;
        }, onMouseLeave: (e) => {
            e.currentTarget.style.border = '';
            e.currentTarget.style.scale = '1.0';
        }, onClick: handleClick, children: [images[currentImageIndex].url.includes('.mp4') ? ((0, jsx_runtime_1.jsx)("video", { src: images[currentImageIndex].url, style: imageStyle, autoPlay: true, loop: true, muted: true })) : ((0, jsx_runtime_1.jsx)("img", { src: images[currentImageIndex].url, alt: `Card Cover ${currentImageIndex + 1}`, style: imageStyle })), (0, jsx_runtime_1.jsx)("div", { style: messageIconStyle, onMouseEnter: (e) => {
                    e.currentTarget.style.transform = messageIconHoverStyle.transform;
                }, onMouseLeave: (e) => {
                    e.currentTarget.style.transform = '';
                }, onClick: (e) => {
                    e.stopPropagation(); // Prevent triggering the card click
                    onMessageClick();
                }, children: (0, jsx_runtime_1.jsx)(icons_1.MessageOutlined, {}) }), (0, jsx_runtime_1.jsx)("div", { style: indicatorsContainerStyle, children: images.map((_, index) => ((0, jsx_runtime_1.jsx)("div", { style: indicatorStyle(index === currentImageIndex) }, index))) }), (0, jsx_runtime_1.jsxs)("div", { style: overlayStyle, children: [(0, jsx_runtime_1.jsx)("div", { style: titleStyle, children: title }), (0, jsx_runtime_1.jsxs)("p", { style: descriptionStyle, children: ["Age: ", age] }), (0, jsx_runtime_1.jsx)("p", { style: descriptionStyle, children: description })] })] }));
};
exports.default = ImageCard;
