"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const sounds = {
    notification: 'https://hsassets.blob.core.windows.net/generation-assets/sounds/notification_pop.mp3'
};
const useSound = () => {
    const playNotification = () => {
        const audio = new Audio(sounds.notification);
        audio.play();
    };
    return {
        playNotification
    };
};
exports.default = useSound;
