// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cs-message-input__content-editor-wrapper {
  background-color: rgb(14 14 14) !important;
}

.cs-message-input__content-editor {
  background-color: rgb(14 14 14) !important;
  color: #deb887;
}

.cs-button--send {
  color: #deb887;
}

.cs-message__content {
  color: #deb887;
  margin-top: 10px;
}

.content-suggestion:hover {
  border: 1px solid #deb887;
  transition: border-color 0.2s ease;
}

.selected {
  border: 4px solid #deb887 !important;
}

.content-suggestion {
  border: 1px solid white;
  cursor: pointer;
}

.suggestion-category {
  cursor: pointer;
}

.suggestion-category:hover {
  border-bottom: 2px solid #deb887;
  transition: border-color;
}

.selected-category {
  border-bottom: 2px solid #deb887;
  color: #deb887;
}

.ant-image-mask {
  border-radius: 25px;
}

.ant-avatar img {
  object-position: top !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/chat/styles.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;AAC5C;;AAEA;EACE,0CAA0C;EAC1C,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,kCAAkC;AACpC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gCAAgC;EAChC,wBAAwB;AAC1B;;AAEA;EACE,gCAAgC;EAChC,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".cs-message-input__content-editor-wrapper {\r\n  background-color: rgb(14 14 14) !important;\r\n}\r\n\r\n.cs-message-input__content-editor {\r\n  background-color: rgb(14 14 14) !important;\r\n  color: #deb887;\r\n}\r\n\r\n.cs-button--send {\r\n  color: #deb887;\r\n}\r\n\r\n.cs-message__content {\r\n  color: #deb887;\r\n  margin-top: 10px;\r\n}\r\n\r\n.content-suggestion:hover {\r\n  border: 1px solid #deb887;\r\n  transition: border-color 0.2s ease;\r\n}\r\n\r\n.selected {\r\n  border: 4px solid #deb887 !important;\r\n}\r\n\r\n.content-suggestion {\r\n  border: 1px solid white;\r\n  cursor: pointer;\r\n}\r\n\r\n.suggestion-category {\r\n  cursor: pointer;\r\n}\r\n\r\n.suggestion-category:hover {\r\n  border-bottom: 2px solid #deb887;\r\n  transition: border-color;\r\n}\r\n\r\n.selected-category {\r\n  border-bottom: 2px solid #deb887;\r\n  color: #deb887;\r\n}\r\n\r\n.ant-image-mask {\r\n  border-radius: 25px;\r\n}\r\n\r\n.ant-avatar img {\r\n  object-position: top !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
